import React, { useState } from "react";
import {
    Button, Popover, Col, Row
} from "antd";
import {
    ImportOutlined,
    DownloadOutlined
} from "@ant-design/icons";

const FileImporter = ({ setSteps, setPlanName, setFTP, setDescription, FTP, steps, planName, description, createWarmUpSteps, isMobile }) => {
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");
    const usePercentage = true;
    function downloadErg() {
        const descriptionText = description ? `\nDESCRIPTION = ${description}` : ""
        // Generate ERG file content
        let ergContent = `[COURSE HEADER]
VERSION = 2
UNITS = ENGLISH
FILE NAME = ${planName}${descriptionText}
FTP = ${FTP}
POWER MODE = ${usePercentage ? "PERCENTAGE" : "WATTS"}
MINUTES WATTS
[END COURSE HEADER]
[COURSE DATA]
`;

        // Add steps to the COURSE DATA
        let totalMinutes = 0;
        let lastPercentageOfFTP = null;
        steps.forEach((step, i) => {
            const { duration, watt } = step;
            if (lastPercentageOfFTP !== null) {
                ergContent += `${totalMinutes.toFixed(2)}\t${lastPercentageOfFTP.toFixed(2)}\n`;
            }
            const percentageOfFTP = (watt / FTP) * 100;
            ergContent += `${totalMinutes.toFixed(2)}\t${percentageOfFTP.toFixed(2)}\n`;
            totalMinutes += duration / 60; // Convert seconds to minutes
            lastPercentageOfFTP = percentageOfFTP
            if (i == steps.length - 1) {
                ergContent += `${totalMinutes.toFixed(2)}\t${percentageOfFTP.toFixed(2)}\n`;
            }
        });

        ergContent += `[END COURSE DATA]`;

        // Create a downloadable file
        const blob = new Blob([ergContent], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "simplyride_app_workout.erg";
        link.click();
        URL.revokeObjectURL(url);
    }

    const processErgFile = (fileContent, mergeSameWattSteps = false) => {
        const lines = fileContent.split("\n");
        let filename = "";
        let description = "";
        let steps = [];
        let comment = null;
        let watts = null;
        let minutes = null;
        let inCourseData = false;
        let lastMin = 0; // Dernière minute traitée
        let lastWatt = 0; // Dernière puissance traitée
        let isFTPPresent = false;
        let powerMode = "WATTS";

        lines.forEach((line) => {
            if (line.startsWith("FILE NAME")) {
                filename = line.split("=")[1].trim();
            }
            if (line.startsWith("FTP")) {
                FTP = parseInt(line.split("=")[1].trim());
                isFTPPresent = true
            }
            if (line.startsWith("DESCRIPTION")) {
                description = line.split("=")[1].trim();
            }
            if (line.startsWith("POWER MODE")) {
                powerMode = line.split("=")[1].trim();
            }

            if (line.startsWith("[COURSE DATA]")) {
                inCourseData = true;
                return;
            }

            if (line.startsWith("[END COURSE DATA]")) {
                inCourseData = false;
                return;
            }

            if (inCourseData) {
                let data = line.split("\t").map((val) => val.trim());
                if (data.length === 2) {
                    [minutes, watts] = data;
                    comment = null;
                    console.log(watts, minutes)

                }
                else if (data.length === 3) {
                    [minutes, watts, comment] = data

                }
                else {
                    setError("Erg should contains 2 (watts and minutes) or 3 columns (watts, minutes and comment)")
                }
                watts = powerMode === "PERCENTAGE" ? parseInt(watts * FTP / 100) : watts //deal wil percentage of FTP

                if (!isNaN(minutes) && !isNaN(watts)) {
                    const currentMinutes = parseFloat(minutes);
                    const currentWatts = parseInt(watts, 10);

                    // Calculer la durée entre deux points
                    const duration = Math.round((currentMinutes - lastMin) * 60); // Convertir en secondes

                    if (duration > 0) {
                        if (
                            mergeSameWattSteps &&
                            steps.length > 0 &&
                            steps[steps.length - 1].watt === lastWatt
                        ) {
                            // Fusionner les étapes avec la même puissance
                            steps[steps.length - 1].duration += duration;
                        } else {
                            // Ajouter une nouvelle étape
                            steps.push({
                                duration,
                                watt: lastWatt,
                                comment: comment,
                                selected: false,
                            });
                        }
                    }

                    // Mettre à jour pour la prochaine étape
                    lastMin = currentMinutes;
                    lastWatt = currentWatts;
                }
            }
        });


        // Mettre à jour le plan avec les nouvelles données
        setPlanName(filename);
        setSteps(steps);
        setFTP(FTP)
        setDescription(description)

    };

    const processZwoFile = (fileContent) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(fileContent, "text/xml");

        // Ensure the sport type is "bike"
        const sportType = xmlDoc.getElementsByTagName("sportType")[0]?.textContent;
        if (sportType !== "bike") {
            setError("This workout is not for cycling. Only 'bike' workouts are supported.");
            return;
        }

        const author = xmlDoc.getElementsByTagName("author")[0]?.textContent || "Unknown";
        const filename = xmlDoc.getElementsByTagName("name")[0]?.textContent || "Untitled Workout";
        const description = xmlDoc.getElementsByTagName("description")[0]?.textContent || "";

        const workoutSteps = xmlDoc.getElementsByTagName("workout")[0]?.children;
        if (!workoutSteps || workoutSteps.length === 0) {
            console.error("No workout steps found in the file.");
            return;
        }

        let steps = [];
        let unsupportedSteps = ""
        // Parse each step
        Array.from(workoutSteps).forEach((step) => {
            const tagName = step.tagName;

            switch (tagName) {
                case "SteadyState": {
                    const duration = parseInt(step.getAttribute("Duration"), 10); // in seconds
                    const power = parseFloat(step.getAttribute("Power")); // %FTP
                    const watt = Math.round(power * FTP); // Convert %FTP to watts
                    const cadence = step.getAttribute("Cadence")
                    steps.push({ duration, watt, comment: cadence ? `@ ${cadence}rpm` : null, selected: false });
                    break;
                }
                case "Ramp": {
                    const duration = parseInt(step.getAttribute("Duration"), 10); // in seconds
                    const powerLow = parseFloat(step.getAttribute("PowerLow")); // %FTP
                    const powerHigh = parseFloat(step.getAttribute("PowerHigh")); // %FTP
                    const fromWatt = Math.round(powerLow * FTP);
                    const toWatt = Math.round(powerHigh * FTP);
                    const cadence = step.getAttribute("Cadence")
                    const rampSteps = createWarmUpSteps(duration, fromWatt, toWatt, cadence ? `@ ${cadence}rpm` : "Ramp");
                    steps = steps.concat(rampSteps); // Add generated ramp steps
                    break;
                }
                case "Cooldown": {
                    const duration = parseInt(step.getAttribute("Duration"), 10); // in seconds
                    const powerLow = parseFloat(step.getAttribute("PowerLow")); // %FTP
                    const powerHigh = parseFloat(step.getAttribute("PowerHigh")); // %FTP
                    const fromWatt = Math.round(powerHigh * FTP);
                    const toWatt = Math.round(powerLow * FTP);
                    const cadence = step.getAttribute("Cadence")
                    const rampSteps = createWarmUpSteps(duration, fromWatt, toWatt, cadence ? `@ ${cadence}rpm` : "Cool down");
                    steps = steps.concat(rampSteps); // Add generated ramp steps
                    break;
                }
                case "FreeRide": {
                    const duration = parseInt(step.getAttribute("Duration"), 10); // in seconds
                    const cadence = step.getAttribute("Cadence")
                    steps.push({ duration, watt: 50, comment: cadence ? `@ ${cadence}rpm` : "Free Ride", selected: false });
                    break;
                }
                case "IntervalsT": {
                    const repeat = parseInt(step.getAttribute("Repeat"), 10);
                    const cadence = step.getAttribute("Cadence")
                    const onDuration = parseInt(step.getAttribute("OnDuration"), 10); // in seconds
                    const offDuration = parseInt(step.getAttribute("OffDuration"), 10); // in seconds
                    const onPower = Math.round(parseFloat(step.getAttribute("OnPower")) * FTP); // %FTP to watts
                    const offPower = Math.round(parseFloat(step.getAttribute("OffPower")) * FTP); // %FTP to watts

                    for (let i = 0; i < repeat; i++) {
                        steps.push({ duration: onDuration, watt: onPower, comment: cadence ? `@ ${cadence}rpm` : "Interval On", selected: false });
                        steps.push({ duration: offDuration, watt: offPower, comment: cadence ? `@ ${cadence}rpm` : "Interval Off", selected: false });
                    }
                    break;
                }
                default: {
                    unsupportedSteps += `Unsupported workout step: ${tagName}. `;
                    break;
                }
            }
        });

        // Update the plan with the parsed data
        setPlanName(filename);
        setSteps(steps);
        setFTP(FTP); // Use the global FTP value
        setDescription(description || `Created by ${author}`);
        if (unsupportedSteps !== "") {
            setWarning(unsupportedSteps)
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (file.name.endsWith(".erg")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setError("");
                processErgFile(e.target.result, true);
            };
            reader.readAsText(file);
        } else if (file.name.endsWith(".zwo")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setError("");
                processZwoFile(e.target.result);
            };
            reader.readAsText(file);
        }


        else {
            setError("Only .erg or .zwo files are accepted.");
            return;
        }
    };
    const content =
        <span><p>Currently accepted formats are <b>.erg</b> and <b>.zwo</b>. For more formats please send an example of such file to sam@simplyride.app.</p><p> For .erg file, if POWER MODE = PERCENTAGE is indicated, watt values will be read as percentage of FTP. Absolute watts will be considered otherwise.</p></span>

    return (
        <div>
            <Row>
                <Col span={isMobile ? 24 : 12}>

                    <Popover content={content}>
                        <Button
                            block
                            onClick={() => {
                                if (steps.length == 0
                                    ||
                                    window.confirm("It will overwrite the current plan, contiue ?")
                                ) {
                                    document.getElementById("erg-file-input").click()
                                }
                            }
                            }

                        >

                            <ImportOutlined />{isMobile ? "" : "Plan"}

                        </Button>
                    </Popover>

                </Col>
                {!isMobile &&
                    <Col span={12}>
                        <Button
                            block
                            disabled={steps.length === 0}
                            onClick={() => (downloadErg())}

                        >
                            <DownloadOutlined />
                            .erg
                        </Button>
                    </Col>}
            </Row>
            <input
                id="erg-file-input"
                type="file"
                accept=".erg,.zwo"
                style={{ display: "none" }}
                onChange={handleFileUpload}
            />

            {
                error && (
                    <p style={{ color: "red", marginTop: "10px" }}>
                        {error}
                    </p>
                )
            }
            {
                warning && (
                    <p style={{ color: "orange", marginTop: "10px" }}>
                        {warning}
                    </p>
                )
            }
        </div >
    );
};

export default FileImporter;