import React from 'react';
import { Table, Typography, Button, Divider } from 'antd';
import { DownloadOutlined, FireTwoTone } from '@ant-design/icons';

const { Text } = Typography;
const options = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Brussels',
    timeZoneName: 'short',
};
function estimateDistance(weightCyclist, kjBurned) { //if not mentionned
    const weightBike = 8
    // Données de référence
    const referenceWeightCyclist = 74; // Poids du cycliste de référence en kg
    const referenceWeightBike = 8; // Poids du vélo de référence en kg
    const referenceKjPerKm = 348 / 17.3; // Énergie consommée par km pour 74kg + 8kg
  
    // Masse totale
    const referenceTotalWeight = referenceWeightCyclist + referenceWeightBike; // Masse totale de référence
    const totalWeight = weightCyclist + weightBike; // Masse totale entrée
  
    // Ratio d'énergie consommée par km en fonction de la masse
    const kjPerKm = referenceKjPerKm * (totalWeight / referenceTotalWeight);
  
    // Distance en kilomètres
    const distanceKm = kjBurned / kjPerKm;
  
    // Conversion en mètres
    const distanceMeters = distanceKm * 1000;
    return distanceMeters; // Distance en mètres
  }

  const getZoneDuration = (data, zone) => {
    const perc = [0, 0.6, 0.75, 0.9, 1.05, 1.19, 1.50, 10000].map(p => data.ftp ? p*data.ftp : p*250)
  
    if (data.durationSteps && data.durationSteps.length>0){
    return data.avgSteps.map((x, i) => (x >= perc[zone-1] && x < perc[zone] ? data.durationSteps[i] : 0)).reduce((a, b) => a + b, 0)
    }
    else {
      return data.avg_watt >= perc[zone-1] && data.avg_watt < perc[zone] ? data.duration : 0 // legacy
    }
  }
  
const tcxHelpers = <div style={{ marginTop: "2%" }}>
    ¹ | Average power (Watt) until pause or end of training / Planned average Watt within the completed lap.<p />
    ² | Energy (Kilojoules) spent during training / Total forecasted Energy (Kilojoules) to be spent assuming completion of training.<p />
    ³ | Total duration, average watt, and other metrics do not account for pause time or any activity during that period.<p />
    ⁴ | Although the training plan has this specified name, modifications have been made and the actual plan followed during the session may differ from the original.<p />
    ⁵ | The distance is only an estimate, based on favorable conditions and a flat track, and it varies depending on your weight.<p />
    ⁶ | You can download your activity TCX file and manually upload it to most platforms, such as <a target="_blank" href='https://connect.garmin.com/modern/import-data'>Garmin</a> or <a target="_blank" href='https://www.strava.com/upload/select'>Strava</a>. To save your session as 'virtual biking' on Strava, you need to upload it to Strava via the checkbox above. After that, the TCX file will be tagged as "biking" and it won't be possible to change it to "virtual biking".
    <p />
</div>
const History = ({ history, isMobile }) => {
    function formatDate(dateString) {
        const date = new Date(dateString);

        // Belgian locale and time zone
        const options = {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'Europe/Brussels',
            timeZoneName: 'short',
        };

        const formatter = new Intl.DateTimeFormat('en-BE', options);
        const formattedDate = formatter.format(date);

        return formattedDate.replace("CEST", "");
    }


    function getDayOfMonthWithSuffix(day) {
        if (day > 3 && day < 21) return `${day}th`; // for 11th, 12th, 13th, etc.
        switch (day % 10) {
            case 1: return `${day}st`;
            case 2: return `${day}nd`;
            case 3: return `${day}rd`;
            default: return `${day}th`;
        }
    }
    const filterLatestByTimestamp = (items) => {
        // Use reduce to iterate through the array
        const latestItemsMap = items.reduce((map, item) => {
            const existingItem = map.get(item.start_date);
            // If there's an existing item with the same start_date, compare the save times
            if (!existingItem || new Date(item.ts) > new Date(existingItem.ts)) {
                // If the current item is saved later, update the map with the current item
                map.set(item.timestamp, item);
            }
            return map;
        }, new Map());

        // Convert the Map back to an array
        return Array.from(latestItemsMap.values()).sort((a, b) => a.ts > b.ts ? -1 : 1);
    };
    function formatTime(seconds) {
        let reverse = false;
        if (seconds < 0) {
            seconds = -seconds;
            reverse = true;
        }
        const minutes = Math.floor(seconds / 60); // Get the full minutes
        const remainingSeconds = seconds % 60;    // Get the remaining seconds
        // Return the formatted time string
        // Add a leading zero to seconds if less than 10
        const timeStr = reverse ? `- ${minutes}'${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}` : `${minutes}'${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
        return timeStr;
    }
    const mobileColKeys = ["display_name", "start_time", "progress_steps", "comment", "duration", "avg_watt", "km", "tcx"]

    const columns = [
        {
            title: 'Name',
            dataIndex: 'display_name',
            key: 'display_name',
            width: "20%",
            render: (text, record) => record.display_name ? <Text strong>{record.isModificationMade ? `${text}⁴` : text}</Text> : (record.init_plan_steps ? <Text strong>Unsaved Plan</Text> : <Text strong>Free ride</Text>)
        },
        {
            title: 'Start time',
            dataIndex: 'start_time',
            key: 'start_time',
            width: "20%",
            sorter: (a, b) => new Date(b.start_time) - new Date(a.start_time), // Reverse order
            render: (text, record) => {
                try { return formatDate(text) }
                catch {
                    return text
                }
            }
        },
        {
            title: 'Laps',
            dataIndex: 'progress_steps',
            key: 'progress_steps',
            width: "8%",
            render: (text, record) => (record.init_plan_steps ?
                <Text >{record.progress_steps || 0}/{record.init_plan_steps} </Text> : ""
            ),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            width: "15%",
            render: (text, record) => (record.init_plan_duration ?
                <Text >{formatTime(Math.round(text))} / {formatTime(Math.round(record.init_plan_duration))} {`(${Math.round(text / record.init_plan_duration * 100, 2)}%)`}</Text> : formatTime(Math.round(text))
            ),
            //render: (text) => `${formatTime(Math.round(text))} `,
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            width: "15%",
            render: (text, record) => (
                text || "No comment"
            ),
        },
        {
            title: <FireTwoTone />,
            dataIndex: 'intensity',
            key: 'intensity',
            width: "5%",
            render: (text) => `${text}`,
        },
        {
            title: 'Watt¹',
            dataIndex: 'avg_watt',
            key: 'avg_watt',
            width: "8%",
            render: (text, record) => record.init_plan_avg_watt ? `${Math.round(text)}/${Math.round(record.init_plan_avg_watt_achieved)}` : `${Math.round(text)}`,
        },
        {
            title: 'RPM',
            dataIndex: 'avg_cad',
            key: 'avg_cad',
            width: "8%",
            render: (text) => text ? `${Math.round(text)}` : "",
        },
        {
            title: 'BPM',
            dataIndex: 'avg_bpm',
            key: 'avg_bpm',
            width: "8%",
            render: (text) => text ? `${Math.round(text)}` : "",
        },
        {
            title: 'kJ²',
            dataIndex: 'avg_watt',
            key: 'kjoules',
            width: "8%",
            render: (text, record) => record.init_plan_avg_watt ? `${Math.round(text * record.duration / 1000)}/${Math.round(record.init_plan_avg_watt * record.init_plan_duration / 1000)}` : `${Math.round(text * record.duration / 1000)}`

        },
        {
            title: 'Pause³',
            dataIndex: 'total_pause',
            key: 'total_pause',
            width: "8%",
            render: (text, record) => formatTime(Math.round(text || 0))

        },
        {
            title: 'FTP',
            dataIndex: 'ftp',
            key: 'FTP',
            width: "8%",
            render: (text, record) => `${text}`

        },
        {
            title: 'Km⁵',
            dataIndex: 'total_meters',
            key: 'total_meters',
            width: "8%",
            render: (text, record) => text ? `${Math.round(text/10)/100}` : `${Math.round(estimateDistance(record.weight, record.avg_watt*record.duration/1000)/1000)}`
        },
        {
            title: 'z1',
            dataIndex: 'z1',
            key: 'z1',
            width: "10%",
            render: (text, record) => record.durationSteps ? `${formatTime(getZoneDuration(record, 1))} (${Math.round(getZoneDuration(record, 1)/record.duration*100)}%)` : "/"
        },
        {
            title: 'z2',
            dataIndex: 'z2',
            key: 'z2',
            width: "10%",
            render: (text, record) => record.durationSteps ? `${formatTime(getZoneDuration(record, 2))} (${Math.round(getZoneDuration(record, 2)/record.duration*100)}%)` : "/"
        },
        {
            title: 'z3',
            dataIndex: 'z3',
            key: 'z3',
            width: "10%",
            render: (text, record) => record.durationSteps ? `${formatTime(getZoneDuration(record, 3))} (${Math.round(getZoneDuration(record, 3)/record.duration*100)}%)` : "/"
        },
        {
            title: 'z4',
            dataIndex: 'z4',
            key: 'z4',
            width: "10%",
            render: (text, record) => record.durationSteps ? `${formatTime(getZoneDuration(record, 4))} (${Math.round(getZoneDuration(record, 4)/record.duration*100)}%)` : "/"
        },
        {
            title: 'z5',
            dataIndex: 'z5',
            key: 'z5',
            width: "10%",
            render: (text, record) => record.durationSteps ? `${formatTime(getZoneDuration(record, 5))} (${Math.round(getZoneDuration(record, 5)/record.duration*100)}%)` : "/"
        },
        {
            title: 'z6',
            dataIndex: 'z6',
            key: 'z6',
            width: "10%",
            render: (text, record) => record.durationSteps ? `${formatTime(getZoneDuration(record, 6))} (${Math.round(getZoneDuration(record, 5)/record.duration*100)}%)` : "/"
        },
        {
            title: 'z7',
            dataIndex: 'z7',
            key: 'z7',
            width: "10%",
            render: (text, record) => record.durationSteps ? `${formatTime(getZoneDuration(record, 7))} (${Math.round(getZoneDuration(record, 6)/record.duration*100)}%)` : "/"
        },
        {
            title: 'TCX file⁶',
            key: 'tcx',
            width: "8%",
            render: (_, record) => (
                <Button
                    type={record.with_tcx_link ? "primary" : "primary"}
                    icon={<DownloadOutlined />}
                    href={record.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >

                </Button>
            ),
        },
    ];

    return (
        <div>
            {isMobile && false && <Text><i>Note : More data on computer version.</i><p /></Text>}
            <Table
                columns={isMobile && false ? columns.filter(x => !isMobile || mobileColKeys.includes(x.key)).map((x) => { return { ...x, "width": null } }) : columns} //same for phone
                dataSource={history}//{filterLatestByTimestamp(history)} not working, filtered in backend, sorted via column start_time (and backend but useless)
                rowKey="start_time"
                pagination={false}
                scroll={{ "y": 500, "x" : isMobile ? 2500 : 2500 }}
            />
            <Divider />

            {tcxHelpers}
        </div>
    );
};

export default History;
