import React from 'react';
import { List, Checkbox, Button, Typography } from 'antd';
import { RocketOutlined, NotificationOutlined } from '@ant-design/icons'
import logo_finish from "../finish.png";

const { Text } = Typography;

function formatTime(seconds) {
    let reverse = false
    if (seconds < 0) {
        seconds = -seconds
        reverse = true
    }
    const minutes = Math.floor(seconds / 60); // Get the full minutes
    const remainingSeconds = seconds % 60;    // Get the remaining seconds
    // Return the formatted time string
    // Add a leading zero to seconds if less than 10
    const timeStr = reverse ? `-${minutes}'${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}` : `${minutes}'${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    return timeStr
}

function HomeTrainerProgress({ steps, FTP, currentIndex, isMobile }) {
    const totTimeFinal = steps.map(s => s.duration).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0,
    );
    const TotWatt = steps.map(s => s.duration * s.watt).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0,
    );
    const getColorOld = (intensity) => {
        if (intensity >= FTP * 1.5) {
            return 'rgba(128, 0, 0, 0.6)'; // Red
        } else if (intensity >= FTP * 1.2) {
            return 'rgba(128, 0, 128, 0.6)'; // Yellow
        } else if (intensity >= FTP * 1.05) {
            return 'rgba(255, 0, 0, 0.6)'; // Yellow
        } else if (intensity >= FTP * 0.9) {
            return 'rgba(255, 165, 0, 0.6)'; // Yellow
        } else if (intensity >= FTP * 0.75) {
            return 'rgba(255, 215, 0, 0.6)'; // Yellow
        }
        else if (intensity > FTP * 0.55) {
            return 'rgba(0, 128, 0, 0.6)'; // blue
        } else {
            return 'rgba(153, 204, 255, 0.6)';
        }
    };

    const getColor = (intensity) => {
        if (intensity >= FTP * 1.5) {
            return 'rgba(128, 0, 128, 1)';
        } else if (intensity >= FTP * 1.19) {
            return 'rgba(255, 0, 0, 1)';
        } else if (intensity >= FTP * 1.05) {
            return 'rgba(255, 165, 0, 1)';
        } else if (intensity >= FTP * 0.9) {
            return 'rgba(255, 215, 0, 1)';
        } else if (intensity >= FTP * 0.75) {
            return 'rgba(0, 128, 0, 1)';
        }
        else if (intensity > FTP * 0.6) {
            return 'rgba(153, 204, 255, 1)';
        } else {
            return 'rgba(128, 128, 128, 1)';
        }
    };

    return (
        < div >
            {currentIndex < steps.length - 1 &&
                <List

                    bordered
                    dataSource={!isMobile ? steps.slice(currentIndex + 1) : steps.slice(currentIndex + 1)}
                    renderItem={(step, index) => (
                        <List.Item style={{ backgroundColor: index === 0 ? getColor(step.watt).replace(".1)", ".1)") : getColor(step.watt), borderColor: index === 0 ? "black" : getColor(step.watt) }}>
                            {index == 0 ? <b><NotificationOutlined /> </b> : `${index + currentIndex + 2}.`} {index == 0 && <b>{index + currentIndex + 2}. </b>} <Text strong>{formatTime(step.duration)}</Text> at <Text strong>{Math.round(step.watt)} {isMobile ? "W" : "Watts"}</Text>. {!isMobile && step.comment}
                        </List.Item>
                    )}
                />}

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "150px", // Full viewport height
                }}
            >
                <img
                    src={logo_finish}
                    alt={"Finish"}
                    style={{
                        maxWidth: currentIndex < steps.length - 1 ? "40%" : currentIndex === steps.length - 1 ? "50%" : "60%",
                        objectFit: "contain", // Ensures the image scales well
                    }}
                />
            </div>

        </div >
    );

}

export default HomeTrainerProgress;
